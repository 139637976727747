<template>
  <div>
    <component
      :is="RemoteComponent"
      v-if="RemoteComponent"
    />
  </div>
</template>

<script>
import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';

export default {
  data() {
    return {
      RemoteComponent: null,
    };
  },
  async created() {
    try {
      const component = await Frame(
        'caCpFe',
        null,
        './CaCpApp',
        process.env.VUE_APP_CA_CP_FE_ENTRY_POINT,
        () => {
          const { state: { idUUID: operatorId } } = currentOperatorStore;
          return { operatorId, investorId: this.$route.params.investorId, locale: globalStore.state.locale };
        },
      );
      this.RemoteComponent = component;
    } catch (error) {
      console.error('Load CashAccountTab MC failed', error);
    }
  },
};
</script>
